import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Image as StyledImage } from "../../design-system/Image/Image";
import { FeaturedInTheNews } from "../../design-system/Slices/SliceFeaturedInTheNews/FeaturedInTheNews";
import CustomRichText from "../../CustomRichText";
import { InTheNewsSliceProps } from ".";
import SliceFooterActions from "../SliceFooterActions";
import { InTheNewsDataContent, ObjectFit } from "../../_shared";
import {
  InTheNewsCollection,
  InTheNewsContent,
} from "../../design-system/Slices/SliceInTheNewsCollection/InTheNewsCollection";

interface AllFeaturedInTheNewsSliceProps extends InTheNewsSliceProps {}

export function AllFeaturedInTheNewsSlice({
  slice,
}: AllFeaturedInTheNewsSliceProps) {
  let articles: InTheNewsContent[] = [];
  const hasFooterOptions = slice.primary.cta_options.document;
  const actions =
    hasFooterOptions &&
    slice.primary.cta_options.document.data.slices.length > 0 ? (
      <SliceFooterActions
        slice={slice.primary.cta_options.document.data.slices[0]}
        align={"center"}
      />
    ) : null;

  const articleQuery = useStaticQuery(graphql`
    {
      allPrismicInTheNews(sort: { order: DESC, fields: data___published_date }) {
        nodes {
          data {
            title
            url {
              url
              target
            }
            featured_image {
              alt
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 492
                    formats: [AUTO, WEBP, AVIF]
                    quality: 80
                    webpOptions: { quality: 95 }
                    jpgOptions: { quality: 80 }
                  )
                }
              }
            }
            description {
              richText
            }
            published_date
            right_info
            left_info
          }
        }
      }
    }
  `);

  if (articleQuery.allPrismicInTheNews.nodes.length > 0) {
    articles = articleQuery.allPrismicInTheNews.nodes.map(
      (article: InTheNewsDataContent) => {
        return {
          title: article?.data?.title,
          link: article?.data?.url?.url,
          target: article?.data?.url?.target,
          withDivider: slice.primary.show_divider,
          image: article?.data?.featured_image?.url,
          imageObjectFit: slice.primary.use_images_as_cover
            ? ObjectFit.cover
            : ObjectFit.contain,
          content: article?.data?.description?.richText ? (
            <CustomRichText field={article?.data?.description?.richText} />
          ) : (
            <></>
          ),
          rightInfo: article?.data?.right_info,
          leftInfo: article?.data?.left_info,
        };
      }
    );
  } else {
    return null;
  }

  return (
    <InTheNewsCollection
      postsPerPage={slice.primary.posts_per_page || -1}
      showLoadMore={slice.primary.show_load_more_button}
      content={articles}
      actions={actions}
    />
  );
}
